import { createAction } from '@reduxjs/toolkit';
import createThunkPost from './utils/createThunkPost';

export const setFilters = createAction('setFilters');
export const setSelectedUser = createAction('setSelectedUser');
export const setDownloadedUser = createAction('setDownloadedUser');
export const setViewMode = createAction('setViewMode');

export const updateUsers = createThunkPost('updateUsers', {
  headers: { 'Content-Type': 'application/json' },
});
